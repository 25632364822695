import * as Api from '@ViewModels';
import * as React from 'react';

export interface IPeopleInputContext {
	search: string;
	setSearch(search: string): void;
	onSearchChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const getSearchFromCriteria = (criteria: Api.IContactFilterCriteria[]) => {
	const selected =
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.All) ??
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.Name) ??
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.Company) ??
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.Tag && !x.op);

	return selected?.value || '';
};

// Tabs
export type ContactsPagePeopleSearchTab = 'All' | 'Name' | 'Tag' | 'Company';

export const getCriteriaFromTab = (tab: ContactsPagePeopleSearchTab) => {
	switch (tab) {
		case 'All':
			return Api.ContactFilterCriteriaProperty.All;
		case 'Name':
			return Api.ContactFilterCriteriaProperty.Name;
		case 'Tag':
			return Api.ContactFilterCriteriaProperty.Tag;
		case 'Company':
			return Api.ContactFilterCriteriaProperty.Company;
		default:
			break;
	}
};

export const getTabFromCriteria = (criteria: Api.IContactFilterCriteria[]) => {
	const selected =
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.All) ??
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.Name) ??
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.Company) ??
		criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.Tag);

	switch (selected?.property) {
		case Api.ContactFilterCriteriaProperty.All:
			return 'All';
		case Api.ContactFilterCriteriaProperty.Name:
			return 'Name';
		case Api.ContactFilterCriteriaProperty.Tag:
			return 'Tag';
		case Api.ContactFilterCriteriaProperty.Company:
			return 'Company';
		default:
			return 'All';
	}
};

export const DefaultPeopleFilter: Api.IBulkContactsRequest = {
	filter: {
		criteria: [{ property: Api.ContactFilterCriteriaProperty.All }],
	},
	includeContactIds: [],
};

export const DefaultPeopleSort: Api.ISortDescriptor = {
	sort: 'asc',
	sortBy: 'handle',
};
