export const parseElement = (el: HTMLElement, domLvl: number) => {
	if (domLvl === 1) {
		if (el.tagName === 'BR') {
			// remove extra spacing from Google Docs generated HTML
			el.parentNode.removeChild(el);
		}
	}
	if (!el.style.fontFamily || el.style.fontFamily.toLowerCase().includes('arial')) {
		el.style.fontFamily = 'arial,sans-serif';
		el.style.fontSize = '14px';
	}

	if (el.tagName === 'P') {
		const spans = el.querySelectorAll('span');
		if (spans.length === 1 && el.children.length === 1 && spans[0].innerHTML === '&nbsp;') {
			// remove extra spacing from Microsoft Word generated HTML
			el.parentNode.removeChild(el);
		} else {
			// ensure unnecessary spacing is not added from paragraph tags from Google Docs generated HTML
			el.style.margin = '0';
			el.style.padding = '0';
		}
	}

	if (el.tagName === 'UL' || el.tagName === 'OL') {
		el.style.margin = '0';
		el.style.padding = '0 0 0 40px';
	}

	if (el.children.length) {
		Array.from(el.children as HTMLCollectionOf<HTMLElement>).forEach(child => {
			parseElement(child, domLvl + 1);
		});
	}
};

const surveyBaseUrls = [
	process.env.SURVEYS_URL,
];

const shortLinkBaseUrls = [
	process.env.SHORTLINK_URL,
];

export const linkifySurveyLinks = (pastedText: string, linkText?: string) => {
	const trimmedPastedContent = pastedText?.trim() || '';
	const trimmedLowerContent = trimmedPastedContent.toLocaleLowerCase();

	// @ts-ignore
	if (trimmedLowerContent && surveyBaseUrls.some(x => trimmedLowerContent.startsWith(x))) {
		let end = trimmedPastedContent.length;

		const matchIndex = /(\s|<|&lt;)/gim.exec(trimmedPastedContent)?.index || -1;
		end = matchIndex < 0 ? trimmedPastedContent.length : matchIndex;

		const remainder = trimmedPastedContent.substring(end);
		return `<a target="_blank" href="${trimmedPastedContent.substring(0, end)}">${
			linkText || 'Click here to take the survey'
		}</a>${remainder || ''}`;
	}

	if (
		trimmedLowerContent &&
		trimmedLowerContent.includes('source=survey') &&
		// @ts-ignore
		shortLinkBaseUrls.some(x => trimmedLowerContent.startsWith(x))
	) {
		let end = trimmedPastedContent.length;

		const matchIndex = /(\s|<|&lt;)/gim.exec(trimmedPastedContent)?.index || -1;
		end = matchIndex < 0 ? trimmedPastedContent.length : matchIndex;

		const remainder = trimmedPastedContent.substring(end);
		return `<a target="_blank" href="${trimmedPastedContent.substring(0, end)}">${
			linkText || 'Click here to take the survey'
		}</a>${remainder || ''}`;
	}
	if (
		trimmedLowerContent &&
		trimmedLowerContent.includes('source=event') &&
		// @ts-ignore
		shortLinkBaseUrls.some(x => trimmedLowerContent.startsWith(x))
	) {
		let end = trimmedPastedContent.length;

		const matchIndex = /(\s|<|&lt;)/gim.exec(trimmedPastedContent)?.index || -1;
		end = matchIndex < 0 ? trimmedPastedContent.length : matchIndex;

		const remainder = trimmedPastedContent.substring(end);
		return `<a target="_blank" href="${trimmedPastedContent.substring(0, end)}">${
			linkText || 'Click here to RSVP'
		}</a>${remainder || ''}`;
	}

	return pastedText;
};
