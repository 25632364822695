import * as Api from '@ViewModels';
import * as React from 'react';

interface ICompaniesSearchContext {
	filter: Api.ICompaniesSearchFilter;
	load(filter: Api.ICompaniesSearchFilter, sort: Api.ISortDescriptor): void;
	sort: Api.ISortDescriptor;
	companies: Api.CompaniesViewModel;
}

interface ICompanyInputContext {
	search: string;
	setSearch(search: string): void;
	onSearchChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const getCompanySearchFromCriteria = (criteria: Api.ICompaniesSearchFilter[]) => {
	const selected =
		criteria.find(x => x.property === Api.CompanySearchRequestProperty.All) ??
		criteria.find(x => x.property === Api.CompanySearchRequestProperty.Name) ??
		criteria.find(x => x.property === Api.CompanySearchRequestProperty.Tag);

	// @ts-ignore
	return selected.value || '';
};

// @ts-ignore
export const CompaniesSearchInputContext = React.createContext<ICompanyInputContext>(null);
export const useCompaniesInput = () => {
	const context = React.useContext(CompaniesSearchInputContext);
	if (!context) {
		throw new Error('useCompaniesInput can only be used within the <CompaniesContainer /> component');
	}
	return context;
};

// @ts-ignore
export const CompanySearchContext = React.createContext<ICompaniesSearchContext>(null);
export const useCompaniesSearch = () => {
	const context = React.useContext(CompanySearchContext);
	if (!context) {
		throw new Error('useCompaniesSearch can only be used within the <CompaniesContainer /> component');
	}
	return context;
};

export const DefaultCompaniesFilter: Api.ICompaniesSearchFilter = {
	criteria: [{ property: Api.CompanySearchRequestProperty.Name, value: '' }],
};

export const DefaultCompaniesSort: Api.ISortDescriptor = {
	sort: 'asc',
	sortBy: 'companyName',
};

interface ICompaniesTabContext {
	tab: CompaniesTab;
	setTab(tab: CompaniesTab): void;
}

// Tabs
export type CompaniesTab = 'All' | 'Name' | 'Tag';

export const getCompanyCriteriaFromTab = (tab: CompaniesTab) => {
	switch (tab) {
		case 'All':
			return Api.CompanySearchRequestProperty.All;
		case 'Name':
			return Api.CompanySearchRequestProperty.Name;
		case 'Tag':
			return Api.CompanySearchRequestProperty.Tag;
		default:
			break;
	}
};

export const getCompanyTabFromCriteria = (criteria: Api.ICompaniesSearchFilter[]) => {
	const selected =
		criteria.find(x => x.property === Api.CompanySearchRequestProperty.All) ??
		criteria.find(x => x.property === Api.CompanySearchRequestProperty.Name) ??
		criteria.find(x => x.property === Api.CompanySearchRequestProperty.Tag);

	// @ts-ignore
	switch (selected.property) {
		case Api.CompanySearchRequestProperty.All:
			return 'All';
		case Api.CompanySearchRequestProperty.Name:
			return 'Name';
		case Api.CompanySearchRequestProperty.Tag:
			return 'Tag';
		default:
			return 'All';
	}
};

// @ts-ignore
export const CompaniesTabContext = React.createContext<ICompaniesTabContext>(null);
export const useCompanyTabs = () => {
	const context = React.useContext(CompaniesTabContext);
	if (!context) {
		throw new Error('useCompanyTabs can only be used within the <CompanyContainer /> component');
	}
	return context;
};
