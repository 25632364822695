import * as Api from '@ViewModels';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { logoutAndClearCaches } from '../../../models/UiUtils';
import { UserSessionContext } from '../../../viewmodels/AppViewModels';

interface IProps extends RouteComponentProps<any> {
	isAida?: boolean;
	userSession?: UserSessionContext;
	textMessaging?: Api.TextMessagingViewModel;
}

class _Logout extends React.PureComponent<IProps> {
	public componentDidMount() {
		logoutAndClearCaches();
	}

	public render() {
		return (
			<Redirect
				push={true}
				to={{
					pathname: '/login',
					state: { isAida: this.props.isAida },
				}}
			/>
		);
	}
}

export const Logout = withRouter(_Logout);
